import { Injectable, Signal } from "@angular/core";
import { SignalStore, SignalStoreConfig } from "./signal-store";

export interface LangOption {
  lang: string;
  code: LanguageCode;
}

export enum LanguageCode {
  EN = "en",
  SV = "sv",
}

export const languages: Record<LanguageCode, LangOption> = {
  [LanguageCode.EN]: {
    code: LanguageCode.EN,
    lang: "English",
  },
  [LanguageCode.SV]: {
    code: LanguageCode.SV,
    lang: "Swedish",
  },
} as const;

export interface LanguageStoreModel {
  code: LanguageCode;
}

export const languageStoreConfig: SignalStoreConfig<LanguageStoreModel> = {
  initState: {
    code: LanguageCode.SV,
  },
  syncStorage: true,
};

@Injectable({
  providedIn: "root",
})
export class LanguageStore {
  private signalStore = new SignalStore<LanguageStoreModel>(
    "language",
    languageStoreConfig,
  );

  getCurrentLangOptions(): Signal<LanguageStoreModel> {
    languages[LanguageCode.EN];
    return this.signalStore.select((language) => language);
  }

  getAvailableLangOptions(): LangOption[] {
    return Object.values(languages);
  }

  setCurrentLang(code: LanguageCode) {
    this.signalStore.update((_language) => ({
      ...this.getLanguageOption(code),
    }));
  }

  private getLanguageOption(code: LanguageCode): LanguageStoreModel {
    return Object.values(languages).find(
      (lang) => lang.code === code,
    ) as LanguageStoreModel;
  }
}
