import { HttpErrorResponse } from "@angular/common/http";
import { WritableSignal } from "@angular/core";
import { Alert, AlertType } from "@kalmarenergi/util/models/alert";
import { Observable, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { prepare } from "./prepare.operator";

export const handleSubjectError =
  <T>(alert: Subject<Alert | null>, message: string | undefined = undefined) =>
  (source: Observable<T>): Observable<T> =>
    source.pipe(
      prepare(() => alert.next(null)),
      catchError((error: ErrorEvent | HttpErrorResponse) => {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = error.error.message;
        } else if (error instanceof HttpErrorResponse) {
          // server-side error
          errorMessage = generateMessageFromHttpError(error);
        }
        alert.next({
          message: message ? message : errorMessage,
          type: AlertType.error,
        });
        return throwError(() => new Error(errorMessage));
      }),
    );

export const handleError =
  <T>(
    alert: WritableSignal<Alert | null>,
    message: string | undefined = undefined,
  ) =>
  (source: Observable<T>): Observable<T> =>
    source.pipe(
      prepare(() => alert.set(null)),
      catchError((error: ErrorEvent | HttpErrorResponse) => {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = error.error.message;
        } else if (error instanceof HttpErrorResponse) {
          // server-side error
          errorMessage = generateMessageFromHttpError(error);
        }
        alert.set({
          message: message ? message : errorMessage,
          type: AlertType.error,
        });
        return throwError(() => new Error(errorMessage));
      }),
    );

type HttpErrorDetails = { [key: string]: string[] };
export interface HttpError {
  message?: string;
  statusCode?: number;
  code?: string;
  details?: HttpErrorDetails;
  id?: number;
}

export const generateMessageFromHttpError = (
  error: HttpErrorResponse & { error: any | null | HttpError },
) => {
  let message = "";

  switch (error.status) {
    case 400:
      message = $localize`:@@error.badRequest:Bad request. Please check your input and try again.`;
      break;
    case 401:
      message = $localize`:@@error.unauthorized:You are not authorized to access this resource. Please log in and try again.`;
      break;
    case 403:
      message = $localize`:@@error.forbidden:You are not allowed to access this resource. Please contact your administrator.`;
      break;
    case 404:
      message = $localize`:@@error.notFound:The requested resource was not found. Please check the URL and try again.`;
      break;
    case 408:
      message = $localize`:@@error.requestTimeout:Request timeout. Please try again later.`;
      break;
    case 429:
      message = $localize`:@@error.tooManyRequests:Too many requests. Please try again later.`;
      break;
    case 500:
      message = $localize`:@@error.internalServerError:An internal server error occurred. Please try again later.`;
      break;
    case 502:
      message = $localize`:@@error.badGateway:Bad gateway. Please try again later.`;
      break;
    case 503:
      message = $localize`:@@error.serviceUnavailable:Service unavailable. Please try again later`;
      break;
    case 504:
      message = $localize`:@@error.gatewayTimeout:Gateway timeout. Please try again later.`;
      break;
    default:
      message = error.error?.message || error.message;
  }

  if (error.error?.details) {
    message =
      message +
      "<br/><br/>" +
      "<b>" +
      $localize`:@@error.moreInfo:More information:` +
      "</b>" +
      "<br/>" +
      Object.entries(error.error.details as HttpErrorDetails)
        .map(([key, value]) => `${key}: ${value.join(", ")}`)
        .join("<br/>");
  }

  return message;
};
