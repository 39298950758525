import { DialogOptions } from "./dialog-options.model";
import "@angular/localize/init";

export const DeleteDialogOptions: DialogOptions = {
  title: $localize`Delete`,
  content: $localize`Are you sure you want to delete selected items?`,
  confirmationText: $localize`Delete`,
  cancellationText: $localize`Cancel`,
  confirmationColor: "warn",
};

export const ConfirmDialogOptions: DialogOptions = {
  title: $localize`Confirm`,
  content: $localize`Are you sure?`,
  confirmationText: $localize`Confirm`,
  cancellationText: $localize`Close`,
  confirmationColor: "primary",
};
