import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { StaticRoutes } from "@kalmarenergi/features";
import { AuthenticateGuard, routes as iamRoutes } from "@kalmarenergi/iam";

const routes: Routes = [
  {
    path: "auth",
    children: iamRoutes,
  },
  {
    path: "",
    loadChildren: () =>
      import("./modules/layout/layout.module").then((m) => m.LayoutModule),
    canActivate: [AuthenticateGuard],
  },
  ...StaticRoutes,
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
