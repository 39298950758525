import { WritableSignal } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { finalize } from "rxjs/operators";
import { prepare } from "./prepare.operator";

export const subjectIndicate =
  <T>(indicator: Subject<boolean> | BehaviorSubject<boolean>) =>
  (source: Observable<T>): Observable<T> =>
    source.pipe(
      prepare(() => indicator.next(true)),
      finalize(() => indicator.next(false)),
    );

export const indicate =
  <T>(indicator: WritableSignal<boolean>) =>
  (source: Observable<T>): Observable<T> =>
    source.pipe(
      prepare(() => indicator.set(true)),
      finalize(() => indicator.set(false)),
    );
