import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { Injectable, Signal, computed, inject, signal } from "@angular/core";
import {
  APP_ENVIRONMENT,
  BYPASS_BASE_INTERCEPTOR,
} from "@kalmarenergi/util/config";
import { Scope } from "@kalmarenergi/util/models/auth";
import { catchError, take, tap } from "rxjs";
import { UNAUTHORIZED_FEEDBACK, getAuthInfo } from "../utils";

interface Permissions {
  [resourceName: string]: Array<Scope>;
}

interface AudienceResource {
  resource: string;
  scopes: Scope[];
}

interface AudienceError {
  error: string;
  error_description: string;
}

interface PermissionsResponse {
  [audience: string]: Array<AudienceResource | AudienceError>;
}

/**
 * Manages user permissions by syncing and updating them from the server.
 */
@Injectable({
  providedIn: "root",
})
export class UserPermissionsService {
  private http = inject(HttpClient);
  private environment = inject(APP_ENVIRONMENT);

  private _userPermissions = signal<Permissions>({});
  public userPermissions = this._userPermissions.asReadonly();

  public initialSync = false;
  public isSyncing = signal(false);

  /**
   * Resources that have potential name clashed, for example if a resource is named "message" in multiple APIs.
   */
  private resourceNameClashes: string[] = ["message", "category"];

  /**
   * Updates the user permissions with the provided permissions.
   * @param permissions The new permissions to update with.
   */
  private updateUserPermissions(permissions: Permissions) {
    this._userPermissions.update((prevPermissions) => {
      // Remove permissions for resources not included in the new permissions
      const prevResources = Object.keys(prevPermissions);
      for (let resource in prevResources) {
        if (!Object.keys(permissions).includes(resource)) {
          delete prevResources[resource];
        }
      }

      // Merge new permissions with existing ones
      return {
        ...prevPermissions,
        ...permissions,
      };
    });
  }

  /**
   * Resets all user permissions.
   */
  public resetPermissions() {
    this._userPermissions.set({});
  }

  /**
   * Syncs the user permissions with the server for the specified audiences.
   * @param audiences The audiences to sync permissions for.
   * @returns The updated permissions.
   */
  public syncPermissions(setIsSyncing = true) {
    this.initialSync = true;
    const realm = getAuthInfo()?.realm;

    if (!realm) {
      throw new Error("Couldn't sync permissions. No realm found.");
    }

    const url = `${
      this.environment.loginUrl
    }realms/${realm!}/user-extension/permissions`;

    const context = new HttpContext().set(BYPASS_BASE_INTERCEPTOR, true);
    const { audiences } = this.environment;

    if (setIsSyncing) {
      this.isSyncing.set(true);
    }

    return this.http
      .get<PermissionsResponse>(url, {
        context,
        params: new HttpParams({ fromObject: { audiences } }),
      })
      .pipe(
        tap((response) => {
          const permissions: Permissions = {};
          for (let audience in response) {
            if (response[audience][0].hasOwnProperty("error")) {
              continue;
            }

            for (let permission of response[audience]) {
              const { resource, scopes } = permission as AudienceResource;

              const resourceName = this.getResourceName(resource, audience);

              permissions[resourceName] = scopes;
            }
          }
          this.isSyncing.set(false);
          this.updateUserPermissions(permissions);
        }),
        catchError(() => {
          this.resetPermissions();
          this.isSyncing.set(false);
          return [];
        }),
        take(1),
      );
  }

  /**
   * Returns a signal that indicates whether the user has the specified permissions.
   * @param permissions The permissions to check.
   * @param operator The operator to use for combining multiple permissions.
   *        - "and": All permissions must be satisfied.
   *        - "or": At least one permission must be satisfied.
   * @returns A signal that indicates whether the user has the specified permissions.
   */
  public userHasPermission = (
    permissions: [string, Scope] | [string, Scope][],
    operator: "and" | "or" = "and",
  ): Signal<boolean> =>
    computed(() => {
      if (typeof permissions[0] === "string") {
        return this.userPermissions()[permissions[0] as string]?.includes(
          permissions[1] as Scope,
        );
      }

      if (operator === "and") {
        return (permissions as Array<[string, Scope]>).every(
          ([resourceName, scope]) =>
            this.userPermissions()[resourceName]?.includes(scope),
        );
      } else if (operator === "or") {
        return (permissions as Array<[string, Scope]>).some(
          ([resourceName, scope]) =>
            this.userPermissions()[resourceName]?.includes(scope),
        );
      }

      throw new Error("Invalid operator specified.");
    });

  /**
   * Executes the specified feature if the user has the specified permissions.
   * @param feature The feature to execute.
   * @param permissions The permissions to check.
   * @returns The result of the feature if the user has the specified permissions.
   * @throws An error if the user doesn't have the specified permissions.
   * @example
   * ```ts
   * executeIfPermitted(() => console.log("Feature executed"), ["shift", "create"]);
   * ```
   * @example
   * ```ts
   * executeIfPermitted(() => console.log("Feature executed"), [
   *  ["shift", "create"],
   *  ["shift", "update"],
   * ]);
   * ```
   *
   */
  executeIfPermitted(
    feature: () => any,
    permissions: [string, Scope] | Array<[string, Scope]>,
  ): void {
    let authorized = false;

    if (typeof permissions[0] === "string") {
      authorized = this.userPermissions()[permissions[0] as string]?.includes(
        permissions[1] as Scope,
      );
    } else {
      authorized = (permissions as Array<[string, Scope]>).every(
        ([resourceName, scope]) =>
          this.userPermissions()[resourceName]?.includes(scope),
      );
    }

    if (!authorized) {
      window.alert(UNAUTHORIZED_FEEDBACK);
      return;
    }

    feature();
  }

  /**
   * Returns the resource name based on the initial resource name and audience.
   * If the initial resource name clashes with existing resource names, it appends the audience to the resource name.
   * @param initialResourceName - The initial resource name.
   * @param audience - The audience for the resource.
   * @returns The resource name.
   */
  private getResourceName(
    initialResourceName: string,
    audience: string,
  ): string {
    let resourceName = initialResourceName.replace("_", "-");

    if (this.resourceNameClashes.includes(resourceName)) {
      resourceName = `${audience.replace("-api", "")}-${resourceName}`;
    }

    return resourceName;
  }
}
