import { PagedSortRequest, SearchRequest } from "./request.model";

export class KeRequests {
  /**
   * default
   * @param query
   * @returns
   */
  public static default<T extends SearchRequest>(query?: string): T {
    return <T>{
      query,
    };
  }

  /**
   * count
   * @returns an object based on SearchRequest that has pageIndex one and pageSize zero
   */
  public static count<T extends SearchRequest>(): T {
    return <T>{
      pageIndex: 1,
      pageSize: 0,
    };
  }

  /**
   * paged
   * @param pageIndex
   * @param pageSize
   * @returns  an object based on SearchRequest that has pageIndex equals to one if the parameter is not defined
   *           and pageSize fifty if the pageSize parameter is not defined
   */
  public static paged<T extends SearchRequest>(
    pageIndex?: number,
    pageSize?: number,
  ): T {
    return <T>{
      pageSize: pageSize ?? 50,
      pageIndex: pageIndex ?? 1,
    };
  }

  public static pagedSort(sortBy: string): PagedSortRequest {
    return {
      ...KeRequests.paged(),
      sortBy,
    };
  }

  public static searchRequest(query: string, sortBy?: string): SearchRequest {
    return {
      query,
      sortBy,
      pageIndex: 1,
      pageSize: 50,
    };
  }
}
