import { HttpClient } from "@angular/common/http";
import { BaseModel } from "@kalmarenergi/util/models";
import { Observable } from "rxjs";
import { ReadRepository } from "./read.repository";

export class Repository<
  T extends BaseModel,
  CreateRequest,
  SaveRequest extends Partial<T>,
> extends ReadRepository<T> {
  constructor(
    protected override readonly http: HttpClient,
    protected override path: string,
  ) {
    super(http, path);
  }

  public create(request: CreateRequest): Observable<T> {
    return this.http.post<T>(`${this.path}`, request);
  }

  public batchCreate(request: CreateRequest[]): Observable<T[]> {
    return this.http.post<T[]>(`${this.path}/batch`, request);
  }

  public update(resourceId: string, request: SaveRequest): Observable<T> {
    return this.http.put<T>(`${this.path}/${resourceId}`, request);
  }

  public delete(resourceId: string): Observable<void> {
    return this.http.delete<void>(`${this.path}/${resourceId}`);
  }

  public batchDelete(resourceIds: string[]): Observable<void> {
    return this.http.delete<void>(`${this.path}/batch`, {
      body: resourceIds,
    });
  }
}
