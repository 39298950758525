import { HttpContextToken } from "@angular/common/http";
import { InjectionToken } from "@angular/core";
import { Environment } from "@kalmarenergi/util/models";

export const APP_ENVIRONMENT = new InjectionToken<Environment>(
  "Application config",
);

export const BYPASS_BASE_INTERCEPTOR = new HttpContextToken(() => false);

export enum MAT_DIALOG_WIDTHS {
  XS = "400px",
  S = "440px",
  M = "480px",
  L = "520px",
  XL = "560px",
}

export const MAT_DIALOG_DEFAULT_OPTIONS_VALUE = {
  autoFocus: true,
  delayFocusTrap: true,
  width: MAT_DIALOG_WIDTHS.M,
  maxWidth: "80vw",
} as const;
