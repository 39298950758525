import { Component, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { Router, RouterModule } from "@angular/router";
import {
  IdentityService,
  UNAUTHORIZED_FEEDBACK,
  UNAUTHORIZED_POSSIBLE_ACTION,
} from "@kalmarenergi/iam";

@Component({
  standalone: true,
  selector: "ke-feat-unauthorized",
  imports: [RouterModule, MatButtonModule],
  templateUrl: "./unauthorized.component.html",
})
export class UnauthorizedComponent {
  private identityService = inject(IdentityService);
  private router = inject(Router);

  readonly UNAUTHORIZED_FEEDBACK = UNAUTHORIZED_FEEDBACK;
  readonly UNAUTHORIZED_POSSIBLE_ACTION = UNAUTHORIZED_POSSIBLE_ACTION;

  backToHome() {
    this.router.navigate(["/"]);
  }

  logout() {
    this.identityService.logout();
  }
}
