// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from "@kalmarenergi/util/models";

export const environment: Environment = {
  production: false,
  baseApi: "https://ke-apim-dev.azure-api.net/",
  loginUrl: "https://auth-dev.apps.kalmarenergi.se/",
  clientId: "stadshubb",
  audiences: [
    "device-data-api",
    "device-flow-api",
    "device-state-api",
    "integration-data-api",
    "device-api",
    "device-logging-api",
    "device-coding-api",
    "device-alarm-api",
    "notification-api",
    "meter-data-api",
  ],
  realmProviderRealm: "realm-provider",
};
