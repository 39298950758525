import { AuthInfo } from "@kalmarenergi/util/models/auth";
import { COLLECT_AUTH_INFO_ROUTE, REDIRECT_PARAM } from "./iam.constants";

/**
 * Key to use in localstorage for storing user specific auth info
 */
const AUTH_INFO_KEY = "auth-info";

export function clearAuthInfo(): void {
  localStorage.removeItem(AUTH_INFO_KEY);
}

/**
 * Add fetched auth info to storage
 *
 * @param authInfo AuthInfo
 */
export function setAuthInfo(authInfo: AuthInfo): AuthInfo {
  const authInfoString = JSON.stringify(authInfo);

  localStorage.setItem(AUTH_INFO_KEY, authInfoString);
  return authInfo;
}

/**
 * Fetch user info from storage
 *
 * @returns UserLogin | null
 */
export function getAuthInfo(): AuthInfo | null {
  const authInfoString: string | null = localStorage.getItem(AUTH_INFO_KEY);

  return authInfoString ? JSON.parse(authInfoString) : null;
}

export function getQueryStringParams(query: string) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params: { [paramKey: string]: string }, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
}

/**
 * @param queryParams search query params extracted from the url
 * @returns {string} validated string that does not equal to the collect auth info page
 */
function extractRedirectPathFromParams(queryParams: {
  [key: string]: string;
}): string {
  return queryParams.hasOwnProperty(REDIRECT_PARAM) &&
    queryParams[REDIRECT_PARAM] !== COLLECT_AUTH_INFO_ROUTE
    ? queryParams[REDIRECT_PARAM]
    : "/";
}

/**
 * @param {string} searchQuery URL like search query string (includes leading "?" if non-empty). For example window.location.search
 * @returns {string} Redirect path, like "/path/subpath?key=value"
 */
export function getRedirectPath(searchQuery: string) {
  const urlSearchParams = getQueryStringParams(searchQuery);
  return extractRedirectPathFromParams(urlSearchParams);
}

/**
 * Takes a redirect path and extracts arguments needed for router navigation
 * during authentication flow
 *
 * @param redirectPath Where to navigate to user, for example "/path/subPath?key=value"
 * @returns path and query params to be passed to the angular router navigation function
 */
export function getRouterNavigationArgs(redirectPath: string) {
  const queryStartIndex = redirectPath.indexOf("?");
  const queryParams =
    queryStartIndex > -1
      ? getQueryStringParams(redirectPath.slice(queryStartIndex))
      : {};
  const path =
    queryStartIndex > -1
      ? redirectPath.slice(0, queryStartIndex)
      : redirectPath;
  return { path, queryParams };
}
