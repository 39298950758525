import { Route } from "@angular/router";
import { Scope } from "./auth";

interface RouteData {
  authorize: [string, Scope] | [string, Scope][];
}

export interface KeRoute extends Route {
  data?: RouteData;
  children?: KeRoute[];
}
