import { BreakpointObserver } from "@angular/cdk/layout";
import { Injectable, computed, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { map } from "rxjs";
import { SignalStore, SignalStoreConfig } from ".";

export interface SidebarStoreModel {
  isShowing: boolean;
  userHasInteracted: boolean;
}

export const sidebarStoreConfig: SignalStoreConfig<SidebarStoreModel> = {
  initState: {
    isShowing: true,
    userHasInteracted: false,
  },
  equal: (stateA, stateB) =>
    Object.keys(stateA).every(
      (key) =>
        stateA[key as keyof SidebarStoreModel] ===
        stateB[key as keyof SidebarStoreModel],
    ),
};

/**
 * Sidebar store service managing the sidebar state based on SidebarStoreModel.
 */
@Injectable({
  providedIn: "root",
})
export class SidebarStore {
  private breakpointObserver = inject(BreakpointObserver);

  private signalStore = new SignalStore<SidebarStoreModel>(
    "sidebar",
    sidebarStoreConfig,
  );

  /**
   * Signal indicating if the viewport is small (max-width: 638px)
   */
  private viewportIsSmall = toSignal(
    this.breakpointObserver
      .observe(["(max-width: 638px)"])
      .pipe(map((state) => state.matches)),
  );

  /**
   * Get the current value of 'isShowing' from the Sidebar store
   * @returns Signal<boolean>
   */
  getIsShowing() {
    return computed(() => {
      const isShowing = this.signalStore.select((sidebar) => sidebar.isShowing);
      const userHasInteracted = this.getUserHasInteracted();
      const viewportIsSmall = this.viewportIsSmall;

      if (!userHasInteracted()) {
        return !viewportIsSmall();
      }

      return isShowing();
    });
  }

  /**
   * Get the current value of 'userHasInteracted' from the Sidebar store
   * @returns Signal<boolean>
   */
  getUserHasInteracted() {
    return this.signalStore.select((sidebar) => sidebar.userHasInteracted);
  }

  /**
   * Get the current value of 'viewportIsSmall' from the Sidebar store
   * @returns Signal<boolean>
   */
  getViewportIsSmall() {
    return this.viewportIsSmall;
  }

  /**
   * Show the Sidebar by setting 'isShowing' to true
   */
  show() {
    this.signalStore.update((sidebar) => ({
      ...sidebar,
      isShowing: true,
      userHasInteracted: true,
    }));
  }

  /**
   * Hide the Sidebar by setting 'isShowing' to false
   */
  hide() {
    this.signalStore.update((sidebar) => ({
      ...sidebar,
      isShowing: false,
      userHasInteracted: true,
    }));
  }

  /**
   * Toggle the 'isShowing' state of the Sidebar
   * @param show Optional boolean parameter to set the visibility of the Sidebar
   */
  toggleIsShowing(show?: boolean) {
    const isShowing = this.getIsShowing();

    this.signalStore.update((sidebar) => ({
      ...sidebar,
      isShowing: typeof show === "boolean" ? show : !isShowing(),
      userHasInteracted: true,
    }));
  }

  /**
   * Toggle the 'isShowing' state of the Sidebar without setting 'userHasInteracted' to true
   * @param show Optional boolean parameter to set the visibility of the Sidebar
   */
  toggleIsShowingNoInteraction(show?: boolean) {
    const isShowing = this.getIsShowing();

    this.signalStore.update((sidebar) => ({
      ...sidebar,
      isShowing: typeof show === "boolean" ? show : !isShowing(),
    }));
  }
}
