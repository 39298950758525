import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import {
  APP_ENVIRONMENT,
  BYPASS_BASE_INTERCEPTOR,
} from "@kalmarenergi/util/config";
import { Observable } from "rxjs/internal/Observable";

export function baseInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const environment = inject(APP_ENVIRONMENT);

  if (req.context.get(BYPASS_BASE_INTERCEPTOR) === true) {
    return next(req);
  }

  const urlWithBase = req.url.startsWith(environment.baseApi)
    ? req.url
    : `${environment.baseApi}${req.url}`;

  return next(
    req.clone({
      url: urlWithBase,
    }),
  );
}
