import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  LOCALE_ID,
  OnInit,
  ViewChild,
  inject,
  signal,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { Router } from "@angular/router";
import { AlertComponent } from "@kalmarenergi/ui/alert";
import { realisticEmailValidator } from "@kalmarenergi/util/forms";
import { Alert, AlertType } from "@kalmarenergi/util/models/alert";
import { AuthInfo } from "@kalmarenergi/util/models/auth";
import { indicate } from "@kalmarenergi/util/operators";
import { ReplaySubject, firstValueFrom, take } from "rxjs";
import {
  IdentityService,
  RealmProviderService,
  UserPermissionsService,
} from "../../services";
import {
  REDIRECT_PARAM,
  clearAuthInfo,
  getAuthInfo,
  getRedirectPath,
  getRouterNavigationArgs,
} from "../../utils";

@Component({
  selector: "portal-collect-auth-info",
  templateUrl: "./collect-auth-info.component.html",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatCardModule,
    AlertComponent,
  ],
  providers: [RealmProviderService],
})
export class CollectAuthInfoComponent implements OnInit {
  private identityService = inject(IdentityService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private formBuilder = inject(FormBuilder);
  private locale = inject(LOCALE_ID);
  private realmProviderService = inject(RealmProviderService);
  private router = inject(Router);
  private userPermissionsService = inject(UserPermissionsService);

  loginUrls: string[] = [];
  alert = signal<Alert | null>(null);
  indicator = signal<boolean>(false);
  destroyed$ = new ReplaySubject<boolean>(1);

  realmInputIsShowing = signal<boolean>(false);

  @ViewChild("realmControl") realmControl!: ElementRef<HTMLInputElement>;

  form: FormGroup<{
    email: FormControl<string | null>;
    realm: FormControl<string | null>;
  }>;

  constructor() {
    const { username = "", realm } = getAuthInfo() || {};
    const computedRealm = username && realm ? realm : "";

    // Initialize form with auth info from previous timed out session if available
    this.form = this.formBuilder.group({
      email: [username, [Validators.required, realisticEmailValidator()]],
      realm: [computedRealm, []],
    });
  }

  ngOnInit() {
    this.navigateUserIfLoggedIn();
  }

  /**
   * Navigates the user if already logged in.
   * This method checks if the user is already logged in by retrieving the authentication information.
   *
   * This method is intended to be called when the component is initialized.
   */
  async navigateUserIfLoggedIn() {
    const authInfo = getAuthInfo();
    if (!authInfo || !authInfo.realm) {
      return;
    }

    const isLoggedIn = await this.identityService.initialize(authInfo);
    if (isLoggedIn) {
      // Navigates the user if already logged in since this page is unnecessary for a logged in user
      this.redirectUserToApplication();
    }
  }

  onSubmit() {
    this.alert.set(null);

    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const emailValue = this.form.controls.email.value!.trim();
    const realmValue = this.form.controls.realm.value!.trim();

    if (this.realmInputIsShowing()) {
      if (this.form.controls.realm.disabled) {
        this.form.controls.realm.enable();
      }

      this.authenticate({
        username: emailValue,
        realm: realmValue,
      });
      return;
    }

    // Reset realm value if email has changed
    const authInfo = getAuthInfo();
    if (realmValue && authInfo && authInfo?.username !== emailValue) {
      this.form.controls.realm.setValue("");
      clearAuthInfo();
    }

    this.realmProviderService
      .getRealmHint(this.form.value.email!)
      .pipe(indicate(this.indicator), take(1))
      .subscribe({
        next: (response) => {
          if (response.hint) {
            // If realm hint is provided, user is found in exactly one realm - authenticate
            this.authenticate({
              username: emailValue,
              realm: response.hint,
            });
          } else {
            // If no realm hint is provided, show realm input
            if (
              !!this.form.controls.realm.value &&
              this.form.controls.realm.value.trim().length > 0
            ) {
              // Disables realm input if it's prefilled with value from previous session
              this.form.controls.realm.disable();
            }

            this.form.controls.realm.setValidators([Validators.required]);
            this.realmInputIsShowing.set(true); // Show realm input
            this.changeDetectorRef.detectChanges(); // Recognise changes in the DOM

            if (this.form.controls.realm.enabled) {
              this.realmControl?.nativeElement.focus();
              this.form.controls.realm.markAsPristine();
            }
          }
        },
        error: (errResponse) => {
          if (errResponse.status === 404) {
            if (this.shouldAuthenticateKEUser(emailValue)) {
              this.authenticate({
                username: emailValue,
                realm: "kalmarenergi",
              });
              return;
            }

            this.form.controls.email.setErrors({ notFound: true });
            return;
          }

          this.setServerErrorAlert(errResponse.error?.error);
        },
      });
  }

  private shouldAuthenticateKEUser(email: string): boolean {
    const emailDomain = email.split("@")[1];
    return emailDomain === "kalmarenergi.se";
  }

  private async authenticate(authInfo: AuthInfo): Promise<void> {
    this.indicator.set(true);
    try {
      const isLoggedIn = await this.identityService.initialize(authInfo);
      if (isLoggedIn) {
        this.redirectUserToApplication();
        return;
      }

      const redirectPath = getRedirectPath(window.location.search);
      const redirectUri = `${this.identityService.getBaseUrl()}auth/redirect?${REDIRECT_PARAM}=${redirectPath}`;

      await this.identityService.login({
        loginHint: authInfo.username,
        locale: this.locale,
        redirectUri,
      });
    } catch (err) {
      this.alert.set({
        message: $localize`Login process failed. Please, confirm that entered values are correct and try again.`,
        type: AlertType.error,
      });
    }
    this.indicator.set(false);
  }

  enableRealmInput() {
    this.form.controls.realm.enable();

    const realmInputElement = this.realmControl?.nativeElement;
    this.changeDetectorRef.detectChanges(); // Required for smooth label offset transition
    realmInputElement.focus();
    realmInputElement.setSelectionRange(0, realmInputElement.value.length);
  }

  /**
   * COMPONENT UTILS
   */
  setServerErrorAlert(error: string = $localize`Server error`) {
    const message = $localize`An error occurred while trying to authenticate. Information: ${error}`;

    this.alert.set({
      type: AlertType.error,
      message,
    });
  }

  async redirectUserToApplication() {
    try {
      await firstValueFrom(this.userPermissionsService.syncPermissions());
    } catch (error) {
      console.error(
        "Error syncing permissions in collect auth info. Error: ",
        error,
      );
    }

    const redirectPath = getRedirectPath(window.location.search);
    const { path, queryParams } = getRouterNavigationArgs(redirectPath);

    this.router.navigate([path], { queryParams });
  }
}
