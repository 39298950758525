import "@angular/localize/init";

interface TypeStrings {
  title: string;
  content: string;
  confirmationText: string;
  cancellationText: string;
}

interface ConfirmDialogTypeStrings {
  [key: string]: TypeStrings;
}

const DialogStrings: ConfirmDialogTypeStrings = {
  Delete: {
    title: $localize`Delete`,
    content: $localize`Are you sure you want to delete selected items?`,
    confirmationText: $localize`Delete`,
    cancellationText: $localize`Cancel`,
  },
  Resolve: {
    title: $localize`Resolve`,
    content: $localize`Are you sure that you want to change status to Resolved on selected items?`,
    confirmationText: $localize`Resolve`,
    cancellationText: $localize`Close`,
  },
  Confirm: {
    title: $localize`Confirm`,
    content: $localize`Are you sure?`,
    confirmationText: $localize`Confirm`,
    cancellationText: $localize`Close`,
  },
};

export { DialogStrings };
