import { Injectable, inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { firstValueFrom } from "rxjs";
import { IdentityService, UserPermissionsService } from "../services";
import { COLLECT_AUTH_INFO_ROUTE, REDIRECT_PARAM, getAuthInfo } from "../utils";

@Injectable({
  providedIn: "root",
})
export class AuthenticateGuard {
  protected router = inject(Router);
  protected identityService = inject(IdentityService);
  private userPermissionsService = inject(UserPermissionsService);

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.identityService.isLoggedIn()) {
      return true;
    }

    const options = getAuthInfo();
    let isLoggedIn = false;
    if (options) {
      try {
        isLoggedIn = await this.identityService.initialize(options);
      } catch (error) {
        /** Just catch the error */
      }
    }

    if (isLoggedIn) {
      if (!this.userPermissionsService.initialSync) {
        try {
          await firstValueFrom(this.userPermissionsService.syncPermissions());
        } catch (error) {
          console.error(
            "Error syncing permissions in canActivate. Error: ",
            error,
          );
        }
      }
      return true;
    }

    this.collectAuthInfo(state.url);
    return false;
  }

  collectAuthInfo(redirectPath: string) {
    this.router.navigate([COLLECT_AUTH_INFO_ROUTE], {
      queryParams: {
        [REDIRECT_PARAM]: redirectPath,
      },
    });
  }
}
