import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * Covers in more realistic use cases than the native angular email validator.
 *
 * Enforces the pattern anystring@anystring.anystring
 * and prevents multiple @ signs
 *
 * @returns ValidatorFn
 */
export function realisticEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const VALID_EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const emailValid = VALID_EMAIL_PATTERN.test(value);

    return !emailValid ? { email: true } : null;
  };
}
