import { FormGroup } from "@angular/forms";

export function validateForm(form: FormGroup): boolean {
  if (form && !form.valid) {
    Object.keys(form.controls).forEach((field) => {
      const control = form?.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
    return false;
  }
  return true;
}
