import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  Router,
  UrlTree,
} from "@angular/router";
import { UserPermissionsService } from "../services";
import { UNAUTHORIZED_FEEDBACK, UNAUTHORIZED_POSSIBLE_ACTION } from "../utils";

function isAccessAllowed(route: ActivatedRouteSnapshot): boolean | UrlTree {
  const router = inject(Router);
  const { authorize } = route.data;

  if (!authorize) {
    return true;
  }

  const isAuthorized = inject(UserPermissionsService).userHasPermission(
    authorize,
  )();

  if (!isAuthorized) {
    if (router.navigated) {
      // If navigation occurs from within the application, stay on the current route and show alert
      window.alert(`${UNAUTHORIZED_FEEDBACK} ${UNAUTHORIZED_POSSIBLE_ACTION}`);
    } else {
      // If no previous navigation was made, navigate to the unauthorized page
      router.navigate(["unauthorized"]);
    }
    return false;
  }

  return true;
}

export const authorizeGuard: CanActivateChildFn = async (
  childRoute: ActivatedRouteSnapshot,
): Promise<boolean | UrlTree> => {
  try {
    return await isAccessAllowed(childRoute);
  } catch (error) {
    throw new Error(
      "An error happened during access validation. Details:" + error,
    );
  }
};
