import { signal } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Alert } from "@kalmarenergi/util/models/alert";

export abstract class DialogComponent<T, TResult> {
  form: UntypedFormGroup;
  indicator = signal<boolean>(false);
  alert = signal<Alert | null>(null);
  dialogRef: MatDialogRef<T> | null = null;

  /**
   * Constructor
   */
  protected constructor(dialog: MatDialogRef<T>, form: UntypedFormGroup) {
    this.dialogRef = dialog;
    this.form = form;
  }

  /**
   * Save dialog
   */
  submit(): boolean {
    if (this.form && !this.form.valid) {
      Object.keys(this.form.controls).forEach((field) => {
        const control = this.form?.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
      return false;
    }
    this.handleSubmit();
    return false;
  }

  /**
   * Close dialog
   */
  close(data: TResult | null = null): void {
    this.dialogRef?.close(data);
  }

  /**
   * Submit
   */
  abstract handleSubmit(): void;
}
