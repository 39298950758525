import { ComponentType } from "@angular/cdk/portal";
import { Injectable, effect, inject } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmDialogOptions, DialogOptions } from "@kalmarenergi/ui/dialog";
import { take } from "rxjs";
import { IdentityService } from "./identity.service";

@Injectable()
export class RenewSessionService {
  private identityService = inject(IdentityService);
  private dialog = inject(MatDialog);

  authRefreshFailedEffect = effect(() => {
    if (this.identityService.authRefreshFailed()) {
      this.openRenewSession();
    }
  });

  private async openRenewSession() {
    const data: DialogOptions = {
      ...ConfirmDialogOptions,
      title: $localize`You've been logged out`,
      content: $localize`For security reasons, your current session has ended due to inactivity.`,
      cancellationText: $localize`Log in again`,
      confirmationText: $localize`Keep logged out`,
    };

    const renewSessionDialog = await this.openRenewSessionDialog(data);

    renewSessionDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (endSession: boolean) => {
        if (endSession) {
          this.identityService.endSession();
        } else {
          this.identityService.restartSession();
        }
      });
  }

  private async openRenewSessionDialog(
    data: DialogOptions,
  ): Promise<MatDialogRef<any>> {
    const chunk = await import(
      `@kalmarenergi/ui/dialog/components/confirm-dialog.component`
    );

    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    return this.dialog.open(dialogComponent, { data });
  }
}
