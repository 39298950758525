export type MenuItemDisplayName =
  | "Dashboard"
  | "Nodes"
  | "Groups"
  | "Alarms"
  | "Converters"
  | "Products"
  | "Services";
export type MenuCategoryItemDisplayName = "Administration";

export interface MenuItem {
  displayName: MenuItemDisplayName;
  link: string;
  icon: string;
  isActive?: boolean;
}

export interface MenuCategoryItem {
  displayName: MenuCategoryItemDisplayName;
  // link?: string;
  icon: string;
  children: Array<MenuItem>;
  isExpanded?: boolean;
}
