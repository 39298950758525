import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable, from, lastValueFrom } from "rxjs";
import { IdentityService } from "../services";

export function tokenInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  async function handle(req: HttpRequest<any>, next: HttpHandlerFn) {
    const identityService = inject(IdentityService);

    try {
      const bearerToken = identityService.getToken();

      if (!bearerToken) {
        /**
         * If an http request is made without a token, the session has probably expired.
         * By calling this method, the user will be prompted with a dialog to log in again.
         */
        identityService.toggleAuthRefreshFailed(true);
      }

      if (!req.headers.has("Authorization") && !!bearerToken) {
        req = req.clone({
          headers: req.headers.set("Authorization", `Bearer ${bearerToken}`),
        });
      }
    } catch (err) {
      console.error(err);
    }
    return await lastValueFrom(next(req));
  }

  if (req.url.startsWith("assets/")) {
    return next(req);
  }
  return from(handle(req, next));
}
