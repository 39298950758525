/**
 * Enum representing filter operators for query filters.
 */
export enum FilterOperator {
  Any = "any",
  Contains = "cnt",
  ContainsCaseInsensitive = "icnt",
  EndsWith = "ew",
  EndswithCaseInsensitive = "iew",
  Equal = "eq",
  GreaterThan = "gt",
  GreaterThanOrEqual = "gte",
  In = "in",
  IsNull = "null",
  LessThan = "lt",
  LessThanOrEqual = "lte",
  NotAny = "nany",
  NotEqual = "neq",
  NotIn = "nin",
  StartsWith = "sw",
  StartsWithCaseInsensitive = "isw",
}

export enum FilterBehavior {
  And = "and",
  Or = "or",
}

type FilterValue = string | boolean | number | string[] | Date;

export interface Filtration {
  filterBehavior?: FilterBehavior;
  filters?: RequestFilter[];
}

/**
 * Interface representing a request filter used for filtering query results.
 */
export interface RequestFilter {
  field: string;
  operator: FilterOperator;
  value: FilterValue | undefined | null;
}

export interface PagedRequest {
  pageIndex: number;
  pageSize: number;
}

export interface PagedSortRequest extends PagedRequest {
  sortBy?: string;
  sortDescending?: boolean;
}

export type NullsOrder = "first" | "last" | "lastAsc" | "lastDesc";

export interface OrderItem {
  field: string;
  direction: "asc" | "desc";
  nullsOrder?: NullsOrder;
}

export interface PagedOrderRequest extends PagedRequest {
  orderBy?: string[] | string | OrderItem[];
  sortDescending?: boolean;
}

export interface SearchRequest extends PagedSortRequest, PagedOrderRequest {
  query?: string;
  filters?: RequestFilter[];
  filterBehavior?: FilterBehavior;
  fields?: string[];
}
