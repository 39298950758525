import { Component, effect, inject } from "@angular/core";
import { ThemeStore } from "@kalmarenergi/util/store";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title = "Portal";

  private themeStore = inject(ThemeStore);

  darkModeEffect = effect(() => {
    const darkMode = this.themeStore.getDarkMode();

    if (darkMode()) {
      document.body.classList.add("tw-dark-theme");
    } else {
      document.body.classList.remove("tw-dark-theme");
    }
  });
}
