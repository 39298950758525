import { Injectable } from "@angular/core";
import { SignalStore, SignalStoreConfig } from ".";

export interface ThemeStoreModel {
  darkMode: boolean;
}

export const themeStoreConfig: SignalStoreConfig<ThemeStoreModel> = {
  syncStorage: true,
  initState: {
    darkMode: false,
  },
  equal: (stateA, stateB) => stateA.darkMode === stateB.darkMode,
};

/**
 * Theme store service managing the theme state based on ThemeStoreModel.
 */
@Injectable({
  providedIn: "root",
})
export class ThemeStore {
  private themeStore = new SignalStore<ThemeStoreModel>(
    "theme",
    themeStoreConfig,
  );

  /**
   * Retrieves the current dark mode state from the theme store.
   * @returns A signal representing the dark mode state.
   */
  getDarkMode() {
    return this.themeStore.select((theme) => theme.darkMode);
  }

  /**
   * Toggles the dark mode state in the theme store.
   */
  toggleDarkMode() {
    this.themeStore.update((theme) => ({
      ...theme,
      darkMode: !theme.darkMode,
    }));
  }
}
