import { Injectable, InjectionToken, PLATFORM_ID, inject } from "@angular/core";

// Represents an InjectionToken for the local storage object
export const LOCAL_STORAGE = new InjectionToken<Storage>(
  "window local storage object",
  {
    providedIn: "root",
    // Factory function to provide the appropriate Storage based on the platform
    factory: () => {
      return inject(PLATFORM_ID) === "browser"
        ? window.localStorage // If the platform is a browser, use window.localStorage
        : ({} as Storage); // If not a browser, provide an empty object as Storage
    },
  },
);

/**
 * Service for interacting with the local storage.
 */
@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  // The injected storage object based on the platform
  storage = inject(LOCAL_STORAGE);

  constructor() {}

  /**
   * Sets an item in the local storage.
   * @param key - The key to set.
   * @param data - The data to store.
   */
  public setItem(key: string, data: any): void {
    this.storage.setItem(key, JSON.stringify(data));
  }

  /**
   * Retrieves an item from the local storage.
   * @param key - The key of the item to retrieve.
   * @returns The retrieved item.
   */
  public getItem(key: string): any {
    const localStorageValue = this.storage.getItem(key);

    if (!localStorageValue) {
      return null;
    }

    return JSON.parse(localStorageValue);
  }

  /**
   * Removes an item from the local storage.
   * @param key - The key of the item to remove.
   */
  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }
}
