import { Observable } from "rxjs";

export interface DialogOptions {
  title?: string;
  content?: string;
  confirmationText?: string;
  cancellationText?: string;
  submitEvent?: Observable<any>;
  confirmationColor: string;
}
