<div
  class="tw-h-screen tw-w-screen tw-bg-background tw-text-foreground-text tw-flex tw-items-center"
>
  <div
    class="tw-container tw-flex tw-flex-col tw-md:flex-row tw-items-center tw-justify-center tw-px-5"
  >
    <div class="tw-max-w-l">
      <div class="tw-text-5xl tw-font-dark tw-font-bold tw-mb-5" i18n>
        Whoops!
      </div>
      <p
        class="tw-text-2xl tw-md:text-3xl tw-font-light tw-leading-normal tw-mb-5"
      >
        {{ UNAUTHORIZED_FEEDBACK }}
      </p>
      <p class="tw-mb-8">{{ UNAUTHORIZED_POSSIBLE_ACTION }}</p>
      <div class="tw-mb-8 tw-flex tw-flex-wrap tw-gap-4">
        <button
          type="button"
          mat-raised-button
          i18n
          color="primary"
          (click)="backToHome()"
        >
          Back to home
        </button>
        <button type="button" mat-button (click)="logout()" i18n>
          Log out
        </button>
      </div>
    </div>
  </div>
</div>
