<div
  class="tw-bg-ke-blue dark:tw-bg-background tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center"
>
  <mat-card class="tw-w-full tw-max-w-[360px] tw-p-2 tw-mx-2">
    <mat-card-header class="tw-pt-2">
      <mat-card-title i18n>Login</mat-card-title>
      <mat-card-subtitle i18n>Enter email to proceed</mat-card-subtitle>
    </mat-card-header>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-card-content class="!tw-p-4 !tw-pb-0">
        @if (alert(); as alert) {
          <ke-ui-alert [alert]="alert"></ke-ui-alert>
        }
        <div class="tw-flex tw-flex-col tw-gap-1 [&>mat-form-field]:tw-w-full">
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input
              tabindex="1"
              type="email"
              formControlName="email"
              matInput
              placeholder="user@email.com"
              autofocus
              autocomplete="off"
            />
            @if (form.controls["email"].hasError("required")) {
              <mat-error i18n>Required</mat-error>
            }
            @if (form.controls["email"].hasError("email")) {
              <mat-error i18n>Invalid email address</mat-error>
            }
            @if (form.controls["email"].hasError("notFound")) {
              <mat-error i18n>
                Email not found. Please check if you have entered the correct
                email
              </mat-error>
            }
          </mat-form-field>
          @if (realmInputIsShowing()) {
            <mat-form-field class="tw-flex-1">
              <mat-label i18n>Organization</mat-label>
              <input
                tabindex="2"
                #realmControl
                type="text"
                matInput
                placeholder="organization-name"
                i18n-placeholder
                formControlName="realm"
              />
              @if (form.controls.realm.disabled) {
                <mat-hint>
                  <button
                    type="button"
                    tabindex="4"
                    class="tw-bg-transparent tw-underline tw-text-[var(--mat-card-subtitle-text-color)]"
                    i18n
                    (click)="enableRealmInput()"
                  >
                    Change organization
                  </button>
                </mat-hint>
              }
              @if (form.controls["realm"].hasError("required")) {
                <mat-error i18n> Required </mat-error>
              }
            </mat-form-field>
          }
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button
          tabindex="3"
          mat-flat-button
          type="submit"
          [disabled]="!form.valid || indicator()"
          color="primary"
        >
          @if (indicator()) {
            <span i18n>Logging in...</span>
          } @else {
            <span i18n>Log in</span>
          }
        </button>
      </mat-card-actions>
    </form>
    @if (indicator()) {
      <mat-progress-bar
        class="!tw-absolute !tw-left-0 !tw-bottom-0"
        mode="indeterminate"
      ></mat-progress-bar>
    }
  </mat-card>
</div>
