import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { environment } from "@device-env/environment";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing.module";

import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";

import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { IdentityService, tokenInterceptor } from "@kalmarenergi/iam";
import { StandardDateFormatModule } from "@kalmarenergi/ui/date";
import {
  APP_ENVIRONMENT,
  MAT_DIALOG_DEFAULT_OPTIONS_VALUE,
} from "@kalmarenergi/util/config";
import { baseInterceptor } from "@kalmarenergi/util/interceptors";

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    StandardDateFormatModule,
  ],
  providers: [
    { provide: APP_ENVIRONMENT, useValue: environment },
    { provide: MAT_DATE_LOCALE, useValue: "sv-SE" },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: MAT_DIALOG_DEFAULT_OPTIONS_VALUE,
    },
    provideHttpClient(withInterceptors([baseInterceptor, tokenInterceptor])),
    IdentityService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
