import { LocationStrategy } from "@angular/common";
import { Inject, Injectable, LOCALE_ID, inject, signal } from "@angular/core";
import { Router } from "@angular/router";
import { APP_ENVIRONMENT } from "@kalmarenergi/util/config";
import { Environment } from "@kalmarenergi/util/models";
import { AuthInfo } from "@kalmarenergi/util/models/auth";
import Keycloak, { KeycloakProfile } from "keycloak-js";
import { firstValueFrom } from "rxjs";
import {
  COLLECT_AUTH_INFO_ROUTE,
  REDIRECT_PARAM,
  clearAuthInfo,
  getAuthInfo,
  setAuthInfo,
} from "../utils";
import { UserPermissionsService } from "./user-permissions.service";

@Injectable({
  providedIn: "root",
})
export class IdentityService {
  private DEFAULT_MIN_VALIDITY = 300 as const;

  private userPermissionsService = inject(UserPermissionsService);

  private keycloak!: Keycloak;
  private _authRefreshFailed = signal<boolean>(false);
  public authRefreshFailed = this._authRefreshFailed.asReadonly();
  public userProfile = signal<KeycloakProfile | undefined>(undefined);

  constructor(
    private router: Router,
    private readonly locationStrategy: LocationStrategy,
    @Inject(APP_ENVIRONMENT) private environment: Environment,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  private onTokenExpired() {
    const { iat, exp } = this.keycloak.tokenParsed || {};
    const minValidity = exp && iat ? exp - iat : this.DEFAULT_MIN_VALIDITY;

    this.keycloak
      .updateToken(minValidity)
      .then((isRefreshed) => {
        firstValueFrom(this.userPermissionsService.syncPermissions(false));

        if (!isRefreshed) {
          // Token is still valid. Do nothing.
          return;
        }
      })
      .catch(() => {
        // Failed to refresh token
        this.toggleAuthRefreshFailed(true);
      });
  }

  private async onAuthSuccess() {
    try {
      this.toggleAuthRefreshFailed(false);
      const userProfile = await this.loadUserProfile();
      this.userProfile.set(userProfile);
    } catch (error) {
      console.error("Could not load keycloak user profile. Error: ", error);
    }
  }

  private onAuthLogout() {
    this.userProfile.set(undefined);
  }

  private getRedirectUri() {
    return this.router.url;
  }

  private getRealm(hint: string): string {
    if (hint === "kalmar-energi-ab") {
      return "kalmarenergi";
    }
    return hint;
  }

  private navigateToCollectAuthInfo() {
    this.router.navigate([COLLECT_AUTH_INFO_ROUTE], {
      queryParams: {
        [REDIRECT_PARAM]: this.getRedirectUri(),
      },
    });
  }

  public isLoggedIn(): boolean {
    return this.keycloak && this.keycloak.authenticated === true;
  }

  public loadUserProfile(): Promise<KeycloakProfile> {
    return this.keycloak.loadUserProfile();
  }

  public getToken(): string | undefined {
    return this.keycloak?.token;
  }

  public getBaseUrl(): string {
    return `${window.location.origin}${this.locationStrategy.getBaseHref()}`;
  }

  /**
   * Initializes the keycloak client and tries to log in the user
   */
  public async initialize(authInfo: AuthInfo): Promise<boolean> {
    setAuthInfo(authInfo);

    // create keycloak instance
    this.keycloak = new Keycloak({
      url: this.environment.loginUrl,
      realm: this.getRealm(authInfo.realm),
      clientId: this.environment.clientId,
    });

    this.keycloak.onTokenExpired = () => this.onTokenExpired();
    this.keycloak.onAuthSuccess = () => this.onAuthSuccess();
    this.keycloak.onAuthLogout = () => this.onAuthLogout();

    let authenticated = false;

    try {
      authenticated = await this.keycloak.init({
        // login-required
        // will authenticate the client if the user
        // is logged-in to Keycloak or display the login page if not.
        // NOTE: At this point we can not inject user-hint
        // onLoad: "login-required",

        // check- sso
        // will only authenticate the client if the user is already
        // logged-in, if the user is not logged -in the browser will
        // be redirected back to the application and remain unauthenticated.
        onLoad: "check-sso",

        // With silentCheckSso, sso will be performed in a hidden iframe
        // Silent check-sso functionality is limited in some modern browsers.
        // Please see the https://www.keycloak.org/docs/latest/securing_apps/#_modern_browsers with Tracking Protection Section.
        silentCheckSsoRedirectUri: `${this.getBaseUrl()}assets/silent-check-sso.html`,
        silentCheckSsoFallback: true,
        redirectUri: `${this.getBaseUrl()}auth/redirect`,
        checkLoginIframe: true,
        enableLogging: !this.environment.production,
      });
    } catch (error) {
      console.error(
        "Auth error - Could not initialize authentication client. Error: ",
        error,
      );

      clearAuthInfo();

      throw error;
    }

    return authenticated;
  }

  public login(options: Keycloak.KeycloakLoginOptions = {}): Promise<void> {
    return this.keycloak.login(options);
  }

  public logout(): void {
    clearAuthInfo();

    const redirectUri = `${this.getBaseUrl()}${COLLECT_AUTH_INFO_ROUTE.slice(
      1,
    )}`;

    // This will redirect the user to keycloak to logout
    // so we can not do anything passed this point
    this.keycloak.logout({ redirectUri });
  }

  public clearToken() {
    this.keycloak.clearToken();
  }

  public toggleAuthRefreshFailed(value: boolean) {
    this._authRefreshFailed.set(value);
  }

  public endSession() {
    // We can't call logout() at this point since we do not want to
    // logout the user completely from the ISP, only from the application
    this.clearToken();
    this.userPermissionsService.resetPermissions();
    this.toggleAuthRefreshFailed(false);
    this.navigateToCollectAuthInfo();
  }

  public restartSession() {
    const authInfo = getAuthInfo();
    if (!authInfo) {
      this.navigateToCollectAuthInfo();
      return;
    }

    const redirectUri = `${this.getBaseUrl()}auth/redirect?${REDIRECT_PARAM}=${this.getRedirectUri()}`;
    this.login({
      locale: this.locale,
      loginHint: authInfo.username,
      redirectUri,
    });
  }
}
