import { Injectable } from "@angular/core";
import { SignalStore, SignalStoreConfig } from ".";

export interface HeaderStoreModel {
  title: string;
}

export const headerStoreConfig: SignalStoreConfig<HeaderStoreModel> = {
  initState: {
    title: "Dashboard",
  },
  equal: (stateA, stateB) => stateA.title === stateB.title,
};

/**
 * Header store service managing the header state based on HeaderStateModel.
 */
@Injectable({
  providedIn: "root",
})
export class HeaderStore {
  private headerStore = new SignalStore<HeaderStoreModel>(
    "header",
    headerStoreConfig,
  );

  /**
   * Retrieves the current title displayed in the header.
   * @returns A signal representing the current title.
   */
  getTitle() {
    return this.headerStore.select((header) => header.title);
  }

  /**
   * Sets the title to be displayed in the header.
   * @param title - The new title to be set.
   */
  setTitle(title: string) {
    this.headerStore.setProperty("title", title);
  }
}
