import { Pagination } from "./pagination.model";

export class KePaginations {
  /**
   * default pagination
   * @returns
   */
  public static default(pageIndex?: number, pageSize?: number): Pagination {
    return {
      pageSize: pageSize ?? 50,
      pageIndex: pageIndex ?? 1,
    };
  }

  /**
   * get minimal pagination
   * @param pageIndex
   * @param pageSize
   * @returns
   */
  public static minimal(): Pagination {
    return KePaginations.default(1, 10);
  }

  /**
   * unpaged
   * @returns
   */
  public static unpaged(): Pagination {
    return {
      pageSize: -1,
      pageIndex: 1,
    };
  }

  /**
   * count
   * @returns
   */
  public static count(): Pagination {
    return {
      pageIndex: 1,
      pageSize: 0,
    };
  }
}
