/**
 * Created with inspiration from
 * https://medium.com/@sociable_flamingo_goose_694/lightweight-store-service-using-angular-signals-cc0c7909798f
 */

import {
  Signal,
  ValueEqualityFn,
  WritableSignal,
  computed,
  effect,
  inject,
  signal,
} from "@angular/core";
import { APP_ENVIRONMENT } from "@kalmarenergi/util/config";
import { LocalStorageService } from "../services/local-storage.service";

export interface SignalStoreConfig<T> {
  initState: T;
  syncStorage?: boolean;
  equal?: ValueEqualityFn<T>;
}

/**
 * SignalStore class to manage and update the state using signals.
 * @template T - Type of the state object
 */
export class SignalStore<T> {
  private environment = inject(APP_ENVIRONMENT);
  private localStorageService = inject(LocalStorageService);
  readonly STORE_KEY = "@ke-state";

  /**
   * Signal state object that manages the state of the SignalStore.
   */
  private state!: WritableSignal<T>;

  /**
   * Constructor to create an instance of SignalStore.
   * @param storeId - Identifier for the SignalStore instance
   * @param config - Configuration for the SignalStore
   * @param equalityFunction - Optional equalityFunction to prevent redundant value emits when state hasn't changed
   */
  constructor(
    private readonly storeId: string,
    private config: SignalStoreConfig<T>,
  ) {
    this.config = Object.assign(this.config ?? {}, { storeId: this.storeId });

    this.setInitialState();

    // Sync with localStorage
    if (this.config.syncStorage) {
      effect(() => {
        const storageState =
          this.localStorageService.getItem(this.STORE_KEY) || {};
        storageState[this.storeId] = this.state();

        this.localStorageService.setItem(this.STORE_KEY, storageState);
      });
    }

    // Logs state changes in development mode
    if (!this.environment.production) {
      effect(() => {
        console.log(
          `[${
            this.storeId ? this.storeId + " store" : "Unnamed store"
          }] - State was updated: `,
          this.state(),
        );
      });
    }
  }

  /**
   * Sets the initial state signal from storage or config
   */
  setInitialState() {
    if (this.config.syncStorage) {
      const storageState =
        this.localStorageService.getItem(this.STORE_KEY) || {};
      const storeState = storageState[this.storeId];
      this.state = signal(storeState || this.config.initState, {
        equal: this.config.equal,
      });
    } else {
      this.state = signal(this.config.initState, {
        equal: this.config.equal,
      });
    }
  }

  /**
   * Sets the entire state of the store.
   * @param state - New state value to set
   */
  set(state: T) {
    this.state.set(state);
  }

  /**
   * Updates a specific property of the state object.
   * @param key - Key of the property to be updated
   * @param value - New value to set for the property
   */
  setProperty<K extends keyof T>(key: K, value: T[K]) {
    this.state.update((state) => ({
      ...state,
      [key]: value,
    }));
  }

  /**
   * Updates the state using a custom updater function.
   * @param updater - Function that takes the current state and returns the updated state
   */
  update(updater: (state: T) => T) {
    this.state.update(updater);
  }

  /**
   * Resets the state to its initial state.
   */
  reset() {
    this.state.set(this.config?.initState as T);
  }

  /**
   * Selects and returns a specific value from the state using a selector function.
   * @param selector - Selector function to extract a value from the state
   * @returns A computed signal with the selected value from the state
   */
  select<U>(selector: (state: T) => U) {
    return computed(() => selector(this.state()));
  }

  /**
   * Selects and returns a specific value from a provided Signal using a selector function.
   * @param signal - Signal to select a value from
   * @param selector - Selector function to extract a value from the signal
   * @returns A computed signal with the selected value
   */
  selectFrom<T, U>(signal: Signal<T>, selector: (state: T) => U) {
    return computed(() => selector(signal()));
  }
}
