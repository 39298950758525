import { HttpClient, HttpParams } from "@angular/common/http";
import {
  BaseModel,
  KeRequests,
  PagedRequest,
  PagedResponse,
} from "@kalmarenergi/util/models";
import { serializeParameters } from "@kalmarenergi/util/serializers";
import { Observable, map, take } from "rxjs";

export class NestedReadRepository<
  T extends BaseModel,
  GetRequest extends PagedRequest,
> {
  constructor(
    protected readonly http: HttpClient,
    protected basePath: string,
    protected subPath: string,
  ) {}

  public getPath(resourceId: string): string {
    return `${this.basePath}/${resourceId}/${this.subPath}`;
  }

  public get(
    resourceId: string,
    request: GetRequest,
  ): Observable<PagedResponse<T>> {
    return this.http.get<PagedResponse<T>>(`${this.getPath(resourceId)}`, {
      params: new HttpParams({
        fromObject: serializeParameters(request),
      }),
    });
  }

  public getItems(resourceId: string, request: GetRequest): Observable<T[]> {
    return this.get(resourceId, request).pipe(map((x) => x.items));
  }

  public count(
    resourceId: string,
    request: GetRequest = KeRequests.count(),
  ): Observable<number> {
    return this.get(resourceId, request).pipe(
      map((x) => x.count),
      take(1),
    );
  }

  public getById(resourceId: string, subResourceId: string): Observable<T> {
    return this.http.get<T>(`${this.getPath(resourceId)}/${subResourceId}`);
  }
}
