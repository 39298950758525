import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { AlertComponent } from "@kalmarenergi/ui/alert";
import { handleError, indicate } from "@kalmarenergi/util/operators";
import { take } from "rxjs/operators";
import { DialogComponent } from "../dialog.component";
import { DialogOptions } from "../models/dialog-options.model";

@Component({
  selector: "ke-ui-dialog-confirm",
  templateUrl: "./confirm-dialog.component.html",
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, CommonModule, AlertComponent],
})
export class ConfirmDialogComponent extends DialogComponent<
  ConfirmDialogComponent,
  any
> {
  options: DialogOptions;

  constructor(
    private dialog: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions,
  ) {
    super(dialog, new UntypedFormGroup({}));
    this.options = data;
  }

  /**
   * Trigger event when user confirms
   */
  handleSubmit(): void {
    const event = this.data?.submitEvent;

    if (event) {
      event
        .pipe(indicate(this.indicator))
        .pipe(handleError(this.alert))
        .pipe(take(1))
        .subscribe((res) => {
          this.close(res ?? true);
        });
    } else {
      this.close(true);
    }
  }
}
