import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import {
  APP_ENVIRONMENT,
  BYPASS_BASE_INTERCEPTOR,
} from "@kalmarenergi/util/config";

interface RealmsResponse {
  hint?: string;
  message?: string;
  [key: string]: string | undefined;
}

@Injectable()
export class RealmProviderService {
  private http = inject(HttpClient);
  private environment = inject(APP_ENVIRONMENT);

  /**
   * Retrieves the realm information for a given user.
   * @param user The username of the user.
   * @returns A `Observable` that emits the `RealmsResponse` containing the realm information.
   */
  getRealmHint(user: string) {
    const context = new HttpContext().set(BYPASS_BASE_INTERCEPTOR, true);

    const url = `${this.environment.loginUrl}realms/${this.environment.realmProviderRealm}/user-extension/realms`;

    return this.http.get<RealmsResponse>(url, {
      context,
      params: new HttpParams({ fromObject: { user } }),
    });
  }
}
