import { DATE_PIPE_DEFAULT_OPTIONS } from "@angular/common";
import { NgModule } from "@angular/core";

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: "YYYY-MM-dd HH:mm:ss" },
    },
  ],
})
export class StandardDateFormatModule {}
