import { Route } from "@angular/router";

export const StaticRoutes: Route[] = [
  {
    path: "unauthorized",
    loadComponent: () =>
      import("@kalmarenergi/features").then((m) => m.UnauthorizedComponent),
  },
  {
    path: "**",
    loadComponent: () =>
      import("@kalmarenergi/features").then((m) => m.NotFoundComponent),
  },
];
