import { Routes } from "@angular/router";

export const routes: Routes = [
  {
    path: "login",
    loadComponent: () =>
      import("./components").then((c) => c.CollectAuthInfoComponent),
  },
  {
    path: "redirect",
    loadComponent: () =>
      import("./components").then((c) => c.KeAuthRedirectComponent),
  },
];
