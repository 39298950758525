<div
  class="tw-h-screen tw-w-screen tw-bg-background tw-flex tw-items-center tw-text-foreground-text"
>
  <div
    class="tw-container tw-flex tw-flex-col tw-md:flex-row tw-items-center tw-justify-center tw-px-5"
  >
    <div class="tw-max-w-l">
      <div class="tw-text-5xl tw-font-bold tw-mb-5" i18n>404</div>
      <p
        class="tw-text-2xl tw-md:text-3xl tw-font-light tw-leading-normal tw-mb-5"
        i18n
      >
        Sorry we couldn't find this page.
      </p>
      <p class="tw-mb-8" i18n>
        But don't worry, you can find plenty of other things on our homepage.
      </p>
      <a mat-raised-button i18n color="primary" routerLink="/">Back to home</a>
    </div>
    <div class="tw-max-w-lg"></div>
  </div>
</div>
