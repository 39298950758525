<h2 mat-dialog-title class="tw-break-words">{{ options.title }}</h2>
<mat-dialog-content>
  @if (alert(); as alert) {
    <ke-ui-alert [alert]="alert" />
  }
  {{ options.content }}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>
    {{ options.cancellationText }}
  </button>
  <button
    (click)="submit()"
    mat-flat-button
    [color]="options.confirmationColor"
  >
    {{ options.confirmationText }}
  </button>
</mat-dialog-actions>
